/* Arabic font */
@font-face {
  font-family: "IBMPlexSansArabic";
  font-weight: 100;
  src: local("IBMPlexSansArabic"),
    url(./fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Thin.ttf)
      format("truetype");
}

@font-face {
  font-family: "IBMPlexSansArabic";
  src: local("IBMPlexSansArabic"),
    url(./fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "IBMPlexSansArabic";
  font-weight: 500;
  src: local("IBMPlexSansArabic"),
    url(./fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-SemiBold.ttf)
      format("truetype");
}

@font-face {
  font-family: "IBMPlexSansArabic";
  font-weight: 600;
  src: local("IBMPlexSansArabic"),
    url(./fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-SemiBold.ttf)
      format("truetype");
}

@font-face {
  font-family: "IBMPlexSansArabic";
  font-weight: 700;
  src: local("IBMPlexSansArabic"),
    url(./fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf)
      format("truetype");
}

@font-face {
  font-family: "IBMPlexSansArabic";
  font-weight: 900;
  src: local("IBMPlexSansArabic"),
    url(./fonts/IBM_Plex_Sans_Arabic/IBMPlexSansArabic-Bold.ttf)
      format("truetype");
}

/* English font */
@font-face {
  font-family: "Titillium Web";
  font-weight: 100;
  src: local("Titillium Web"),
    url(./fonts/Titillium_Web/TitilliumWeb-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  src: local("Titillium Web"),
    url(./fonts/Titillium_Web/TitilliumWeb-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  font-weight: 500;
  src: local("Titillium Web"),
    url(./fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  font-weight: 600;
  src: local("Titillium Web"),
    url(./fonts/Titillium_Web/TitilliumWeb-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  font-weight: 700;
  src: local("Titillium Web"),
    url(./fonts/Titillium_Web/TitilliumWeb-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Titillium Web";
  font-weight: 900;
  src: local("Titillium Web"),
    url(./fonts/Titillium_Web/TitilliumWeb-Black.ttf) format("truetype");
}

.arabic-section,
.arabic-numeric-input {
  text-align: right;
  direction: rtl;
  font-family: "IBMPlexSansArabic";
  letter-spacing: 0;
}

.english-input,
.english-numeric-input,
.english-section {
  font-family: "Titillium Web", sans-serif;
  text-align: left;
  direction: ltr;
}

body {
  text-align: unset;
}

body,
button,
.modal-title,
.modal-body {
  border-radius: 0;
}

.form-control {
  border-radius: 0;
}

.form-group.required .control-label:after,
.form-check-inline.required .control-label:after,
.arabic .form-group.required .control-label:before {
  content: "*";
  color: #e73426;
}

.arabic .form-group.required .control-label:after {
  content: none;
}

.form-card,
input {
  border-color: #3b3a3b;
}

label {
  color: #3b3a3b;
  font-weight: bold;
  font-size: 14px;
}

.modal {
  z-index: 100000 !important;
}

.searchable-select-antd input:focus {
  box-shadow: none !important;
  border: none !important;
  background-color: white !important;
}

font {
  font-weight: bold;
}

.underline {
  color: #00a84f;
}

.underline:hover,
.underline:active,
.underline:focus {
  color: #00a84f;
  text-decoration: underline;
}

.loyac-btn,
button.btn.loyac-btn {
  color: #00a84f !important;
  border: 2px solid #00a84f !important;
  padding: 10px 40px;
  height: auto;
  font-weight: bold !important;
  background-color: transparent !important;
  font-size: 16px;
  border-radius: 0;
}

.loyac-btn:hover,
.loyac-btn:focus,
.loyac-btn:active,
button.btn.loyac-btn:hover,
button.btn.loyac-btn:focus,
button.btn.loyac-btn:active {
  color: #fff !important;
  outline: none !important;
  text-decoration: none !important;
  border-color: #00a84f !important;
  background-color: #00a84f !important;
  box-shadow: none !important;
}

.loyac-btn-gray {
  color: #3b3a3b !important;
  border: 2px solid #3b3a3b !important;
  font-weight: bold !important;
  background-color: transparent !important;
  font-size: 16px;
}

.loyac-btn-gray:hover,
.loyac-btn-gray:focus,
.loyac-btn-gray:active {
  color: #fff !important;
  outline: none !important;
  text-decoration: none !important;
  border-color: #3b3a3b !important;
  background-color: #3b3a3b !important;
  box-shadow: none !important;
}
.loyac-btn-disabled {
  color: #3b3a3b !important;
  border: 2px solid #3b3a3b !important;
  padding: 10px 40px !important;
  font-weight: bold !important;
  background-color: transparent !important;
  font-size: 16px;
  opacity: 0.7 !important;
}
.loyac-btn.disabled,
.loyac-btn:disabled {
  border-color: #3b3a3b !important;
  background-color: #fff !important;
  box-shadow: none !important;
  opacity: 0.7 !important;
  padding-left: 45px;
  padding-right: 45px;
  color: #3d383d !important;
}

.loyac-btn-yellow {
  color: #000000 !important;
  border-color: #ffd649 !important;
  background-color: #ffd649 !important;
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.loyac-btn-yellow:hover,
.loyac-btn-yellow:focus,
.loyac-btn-yellow:active {
  color: #000000 !important;
  outline: none !important;
  text-decoration: none !important;
  border-color: #ffd649 !important;
  background-color: #ffd649 !important;
  box-shadow: 0 0 5px 2px #ffd649 !important;
}

table.table-bordered > thead > tr > th {
  border: 1px solid #3b3a3b;
}

.ant-table-thead th {
  color: #ffffff;
  text-transform: uppercase;
  background-color: #3b3a3b !important;
}

.green,
.green:hover,
.green:focus,
.green:active {
  color: #00a84f;
}

.gray {
  color: #3b3a3b;
}

.red,
.text-danger {
  color: #e73426 !important;
}

.btn-red {
  color: #e73426 !important;
  border: 2px solid #e73426 !important;
  padding: 10px 40px;
  font-weight: bold !important;
  letter-spacing: 1.5px;
  background-color: transparent !important;
  font-size: 16px;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active {
  color: #fff !important;
  outline: none !important;
  text-decoration: none !important;
  border-color: #e73426 !important;
  background-color: #e73426 !important;
  box-shadow: none !important;
}

.right-align {
  text-align: right;
  font-family: "IBMPlexSansArabic";
}

.alert-danger {
  color: #e73426 !important;
  border-color: #e73426 !important;
  background-color: #ffffff !important;
}

.alert-success {
  color: #00a84f;
  border-color: #00a84f;
  background-color: #ffffff;
}

.alert-dismissible .close {
  color: #00a84f;
}
.no-borders {
  border: none !important;
}

.line {
  text-decoration: line-through;
}

.note {
  font-size: 12px;
  font-weight: 400;
}

.editors {
  width: 100%;
  height: 30vh;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.25rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.RichEditor-styleButton {
  margin: 1.5%;
  padding: 6px;
  color: #3b3a3b;
  font-size: 15px;
  border-radius: 0;
  border: 1.5px solid #3b3a3b;
}

.RichEditor-activeButton {
  color: #fff !important;
  background-color: #3b3a3b !important;
}

.headers {
  margin: 1.5%;
  padding: 6px;
  color: #3b3a3b;
  font-size: 13px;
  font-weight: bold;
  border-radius: 0 !important;
  border: 1.5px solid #3b3a3b;
}

.react-confirm-alert-body {
  text-align: justify !important;
  width: 450px !important;
  font-family: "Titillium Web", sans-serif !important;
}

.react-confirm-alert-body h1 {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}

.home-program-img {
  width: 250px;
  height: 250px;
}

.my-popup-content div {
  text-align: justify;
  text-align-last: right;
  font-family: "IBMPlexSansArabic";
  direction: rtl;
}

.btn {
  outline: none !important;
  box-shadow: none !important;
}

.trash-btn {
  color: #dc3545 !important;
  border: 2px solid #dc3545 !important;
  padding: 10px 40px !important;
  font-weight: bold !important;
  letter-spacing: 1.5px;
  background-color: transparent !important;
  font-size: 16px !important;
}

.trash-btn:hover,
.trash-btn:focus,
.trash-btn:active {
  color: #fff !important;
  background-color: #dc3545 !important;
}

#icon {
  outline: none !important;
  box-shadow: none !important;
}

.f-14 {
  font-size: 14px;
}

.ant-modal {
  width: 600px;
}

.ant-modal.w-1000 {
  width: 1000px !important;
}

.ant-modal-content {
  min-height: 150px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 0px;
}

.loading-dot-wrapper div {
  top: 25%;
}

.ant-message-custom-content span {
  vertical-align: middle;
}

.ql-editor {
  min-height: 150px;
  font-family: "Titillium Web", sans-serif;
  font-size: 16px;
  overflow-y: unset !important;
}

.ql-editor[contenteditable="false"] {
  padding: 0 !important;
}

.ql-hidden {
  height: 0px;
}

.react-quill-ar .ql-editor {
  text-align: right;
  direction: rtl;
  font-family: "IBMPlexSansArabic";
  letter-spacing: 0;
}
.ql-align-right {
  text-align: right !important;
}
.program-carousel {
  direction: initial;
}

.dropdown-menu {
  z-index: 9999;
}

.carousel-root {
  direction: ltr !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: #00a84f !important;
}

.react-tel-input .country-list .country-name,
.react-tel-input .country-list .country .dial-code {
  font-family: "Titillium Web";
  font-size: 14px;
}

.table-custom-chip {
  margin-bottom: 4px !important;
}

.side-card {
  height: 100%;
  position: relative;
}

.side-card .content {
  width: unset !important;
  left: 0;
  right: 0;
}

@media screen and (max-width: 600px) {
  /* Your styles for screens smaller than 600px */
  .side-card .content {
    position: relative !important;
    top: 0;
    bottom: 0 !important;
  }
}

[dir="rtl"] .RFS-ConnectorContainer {
  top: calc(1em - 1.5px);
  right: calc(-50% + 2em - 8px) !important;
  left: calc(50% + 2em - 8px) !important;
  position: absolute;
}

[dir="rtl"] .form-check-label .form-check-input {
  margin-left: 0;
  margin-right: -1.25rem;
}

[dir="rtl"] .form-check {
  padding-left: 0;
  padding-right: 1.25rem;
}
.swiper-pagination-bullet {
  background-color: green !important;
}

.swiper-pagination-bullets {
  margin-bottom: -10px;
}

.cursor-pointer {
  cursor: pointer;
}

.leaderboard_display .ant-form-item-label > label::after {
  content: "" !important;
}
.muiltr-clttge-MuiAutocomplete-root {
  width: 100% !important;
}

.canvasjs-chart-credit {
  display: none;
}
@media (max-width: 1379px) {
  .leaderboard_display .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

span.RFS-StepButtonContent {
  margin-left: 0 !important;
}

.RFS-Label {
  font-family: "Titillium Web", sans-serif;
}

/* when the selected language is ar */
[lang="ar"] {
  .RFS-Label {
    font-family: "IBMPlexSansArabic";
  }

  .ant-form-item-label > label:after {
    margin: 0 2px 0 8px;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin: 0 0 0 12px;
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 26px;
}
.registration-summary-select .Mui-focused {
  color: white !important;
}
.registration-summary-datatable .tss-qbo1l6-MUIDataTableToolbar-actions {
  -webkit-flex: 0 0;
  flex: 0;
}

.dashboard-datatable th:not(:first-child) {
  text-align: center;
}
.dashboard-datatable
  > div
  > div
  > table
  > thead
  > tr
  th[data-colindex="1"]
  > span
  > button {
  margin-left: auto;
  margin-right: auto;
}

.ant-form-vertical .ant-form-item-label {
  text-align: inherit;
}
