.threads_button__badge {
  background-color: #ff7f41;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 18px;
  color: white;
  padding: 2px;
  font-size: 12px;
  position: absolute; /* Position the badge within the relatively positioned button */
  top: 0;
  right: 0;
}
